<template>
	<v-btn
		@click="loadEvents(eventsLastVisible)"
		text
		v-if="!eventsLastVisible.noMore"
	>
		<v-icon> mdi-chevron-down </v-icon>
		Cargar más
	</v-btn>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'event-list-next',

		computed: {
			...mapState({
				eventsLastVisible: state =>
					state.eventBookControl.eventsLastVisible,
			}),
		},

		methods: {
			...mapActions('eventBookControl', ['loadEvents']),
		},
	};
</script>
