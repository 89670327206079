<template>
	<v-dialog v-model="dialog" width="500">
		<!-- <template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				fab
				small
				color="primary"
				class="mr-2"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon dark>mdi-account-switch-outline</v-icon>
			</v-btn>
		</template> -->

		<!-- <v-card>
			<v-card-title> Transferir evento </v-card-title>
			<v-card-text>
				En caso de considerar que este evento le corresponde a otro
				departamento de la asociacion, puede pasarselo seleccionando del
				campo desplegable el area y pulsar el botón transferir.
			</v-card-text>

			<v-card-text>
				<v-select
					v-model="areaid"
					:items="showAreas"
					item-text="name"
					item-value="id"
					label="Derivar al área"
					solo
				></v-select>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					:disabled="!areaid"
					@click="toTransfer()"
				>
					Transferir
				</v-btn>
				<v-btn color="primary" outlined @click="dialog = false">
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card> -->
	</v-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'event-list-chat-list-transfer-form',

		data() {
			return {
				areaid: '',
				dialog: false,
			};
		},

		computed: {
			...mapState({
				areas: state => state.userControl.associationAreas,
				event: state => state.eventBookControl.selectedEvent,
			}),

			showAreas() {
				const areaId = this.event.association_area
					? this.event.association_area.id
					: '';
				/* Filtro el area del evento de la lista a desplegar para que no eliga la misma area */
				return this.areas.filter(item => item.id != areaId);
			},
		},

		methods: {
			...mapActions('eventBookControl', ['toggleEvent']),

			toTransfer() {
				/* Transferir */
				this.toggleEvent({
					transferred_to: this.areaid,
				});

				this.dialog = false;
			},
		},
	};
</script>
