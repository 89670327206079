<template>
	<v-row>
		<v-col cols="12">
			<v-row>
				<v-col cols="3">
					<EventFormAdd />
				</v-col>

				<v-col cols="3" v-if="showElement">
					<EventManageSubject />
				</v-col>
			</v-row>
		</v-col>

		<v-col cols="7">
			<v-card>
				<v-card-text class="users-messages">
					<div>
						<h2>Lista de eventos por rol de regantes</h2>
						<EventListFilter class="mt-2" />
						<EventList heightList="70vh" />
						<p class="text-center mt-2"><EventListNext /></p>
					</div>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="5">
			<EventChat class="position-fix" heightList="60vh" />
		</v-col>
	</v-row>
</template>

<script>
	import EventChat from '@/components/EventChat';
	import EventList from '@/components/EventList';
	import EventFormAdd from '@/components/EventFormAdd';
	import EventListNext from '@/components/EventListNext';
	import EventListFilter from '@/components/EventListFilter';
	import EventManageSubject from '@/components/EventManageSubject';

	import { mapState, mapActions } from 'vuex';

	export default {
		name: 'event-book',

		computed: {
			...mapState({
				associationAreas: state => state.userControl.associationAreas,
				user: state => state.userControl.user,
			}),

			showElement() {
				return (
					this.user &&
					this.user.association_area &&
					!['watchman', 'sectionm'].includes(
						this.user.association_area.code
					)
				);
			},
		},

		mounted() {
			this.unselectEvent();
			this.resetEventsLastVisible();
			this.loadEvents({
				next: 0,
				count: 0,
			});
		},

		destroyed() {
			this.resetEventsLastVisible();
		},

		components: {
			EventChat,
			EventList,
			EventFormAdd,
			EventListNext,
			EventListFilter,
			EventManageSubject,
		},

		methods: {
			...mapActions('eventBookControl', [
				'loadEvents',
				'unselectEvent',
				'resetEventsLastVisible',
			]),
		},
	};
</script>
