<template>
	<v-dialog v-model="dialog" width="500">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				depressed
				fab
				small
				:color="isClose.status ? undefined : 'primary'"
				class="mr-2"
				v-bind="attrs"
				v-on="on"
			>
				<v-icon v-if="isClose.status"
					>mdi-lock-open-variant-outline</v-icon
				>
				<v-icon v-else dark>mdi-message-lock-outline</v-icon>
			</v-btn>
		</template>

		<v-card>
			<v-card-title> {{ isClose.title }} </v-card-title>

			<v-card-text>
				{{ isClose.content }}
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="toggle()">
					{{ isClose.button }}
				</v-btn>
				<v-btn color="primary" outlined @click="dialog = false">
					Cancelar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'event-list-chat-list-close',

		data() {
			return {
				dialog: false,
			};
		},

		computed: {
			...mapState({
				selectedEvent: state => state.eventBookControl.selectedEvent,
			}),

			isClose() {
				const status = this.selectedEvent.closed ? true : false;
				return {
					status: status,
					title: status ? 'Abir evento' : 'Cerrar evento',
					content: status
						? 'Para abrir este evento solo debes pulsar el botón.'
						: 'Para cerrar este evento solo debes pulsar el botón.',
					button: status ? 'Abir evento' : 'Cerrar evento',
				};
			},
		},

		methods: {
			...mapActions('eventBookControl', ['toggleEvent']),

			toggle() {
				this.toggleEvent({
					closed: this.selectedEvent.closed ? false : true, // Si  el evento esta abierto se cierra y viceversa
				});
				this.dialog = false;
			},
		},
	};
</script>
