<template>
	<v-card flat class="event-chat">
		<template v-if="selectedEvent.id">
			<v-card-title>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn depressed fab small class="mr-2" @click="unselectEvent" v-bind="attrs" v-on="on">
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
					</template>
					<span>Cerrar chat</span>
				</v-tooltip>

				<v-spacer></v-spacer>


				<EvenListChatListTrasferForm v-if="!selectedEvent.closed && showButtonTransferEvent" />

				<EvenListChatListClose v-if="showButtonCloseEvent" />


				<v-btn depressed fab small @click="showContentClick" color="primary">
					<v-icon>{{ showContentIcon }}</v-icon>
				</v-btn>
			</v-card-title>

			<v-card-text class="content-event pt-0 pb-0">

				<div class="text-body-1">
					<b>Asunto:</b> {{ selectedEvent.subject }}
				</div>

				<div class="content-info">

					<div v-if="showContent" class="content-description blue lighten-5">
						{{ selectedEvent.content }}
						<div class="text-caption" v-if="
							selectedEvent.irrigator &&
							selectedEvent.irrigator.code
						">
							Regante: {{ selectedEvent.irrigator.name }} (Cod:
							{{ selectedEvent.irrigator.code }})
						</div>
						<div class="text-caption">
							Creado: {{ eventData.created }}
							<template v-if="eventData.association_user">
								- Por:
								{{ eventData.association_user.name }}
							</template>
						</div>
					</div>

					<v-avatar class="content-image" size="100" color="primary">
						<img v-if="eventData.association_user.picture" :src="eventData.association_user.picture"
							alt="Imagen en Base64" class="image-decode-user" style="border-radius:0">
						<v-icon v-else dark>mdi-camera-outline</v-icon>
					</v-avatar>
				</div>

			</v-card-text>

			<v-card-text class="users-messages">
				<div id="message-list" :style="heightList ? `height:${heightList}` : ``">
					<EvenListChatMessage v-for="(msg, index) in messageList" :key="`${index}-msg`" :message="msg" />
				</div>
			</v-card-text>

			<v-card-actions>
				<v-form class="send-message" v-if="!selectedEvent.closed">
					<v-text-field v-model="message" append-outer-icon="mdi-send" prepend-icon="mdi-paperclip"
						clear-icon="mdi-close-circle" clearable label="Su mensaje" type="text" filled rounded dense
						hide-details="auto" @click:clear="clearMessage" @click:append-outer="sendMessage"
						class="input-send-message">
						<template v-slot:prepend>
							<v-file-input :accept="fileToSendAccept" class="pt-0" v-model="fileToSend" hide-input
								hide-details @change="sendMessageAttached"></v-file-input>
						</template>
					</v-text-field>
				</v-form>
			</v-card-actions>
		</template>

		<template v-else>
			<v-card-text class="users-messages">
				<div>
					<v-col>
						<p class="text-center">No hay evento seleccionado.</p>
					</v-col>
				</div>
			</v-card-text>
		</template>
	</v-card>


</template>

<script>
/* Muestra la lista de mensajes del chat */
import { mapState, mapActions, mapGetters } from 'vuex';
import { socket } from '@/plugins/web-sockets';

import EvenListChatMessage from '@/components/EvenListChatMessage';
import EvenListChatListTrasferForm from '@/components/EvenListChatListTrasferForm';
import EvenListChatListClose from '@/components/EvenListChatListClose';

export default {
	name: 'event-chat',

	props: {
		heightList: {
			type: String,
		},
	},

	data: () => {
		return {
			message: null,
			fileToSend: null,
			//fileToSendAccept:"image/*, audio/*, video/*, application/pdf, .doc, .docx, .xlsx, .xls, .pptx, .ppt"
			fileToSendAccept:
				'image/*, application/pdf, .doc, .docx, .xlsx, .xls, .pptx, .ppt',
			showContent: true,
		};
	},

	computed: {
		...mapState({
			selectedEvent: state => state.eventBookControl.selectedEvent,
			user: state => state.userControl.user,
			selectedEventMessages: state =>
				state.eventBookControl.selectedEventMessages,
		}),

		...mapGetters('eventBookControl', ['messageList']),

		showContentIcon() {
			return this.showContent ? 'mdi-chevron-up' : 'mdi-chevron-down';
		},

		eventData() {
			const event = this.$utils.deepCopy(this.selectedEvent);
			event.created = this.$utils
				.moment(event.createdAt)
				.format('DD/MM/YYYY HH:mm');
			return event;
		},

		/* Mostrar boton en caso de no ser celador y un reporte */
		showButtonCloseEvent() {
			return this.user.association_area.code === 'watchman' &&
				(this.selectedEvent.type == 'channelreport' ||
					this.selectedEvent.type == 'visitreport')
				? false
				: true;
		},

		/* Mostrar boton solo en solicitudes de atencion */
		showButtonTransferEvent() {
			return this.selectedEvent.type == 'requestforattention'
				? true
				: false;
		},
	},

	components: {
		EvenListChatMessage,
		EvenListChatListTrasferForm,
		EvenListChatListClose
	},

	mounted() {
		socket.on('message:chat', message => {
			this.pushMessage(message);
		});
	},

	destroyed() {
		this.unselectEvent();
	},

	methods: {
		...mapActions('eventBookControl', [
			'addEventMessage',
			'addFileAttach',
			'pushMessage',
			'unselectEvent',
		]),
		sendMessage() {
			if (this.message.length) {
				this.addEventMessage({
					message: this.message,
				}).finally(() => {
					this.clearMessage();
				});
			}
		},

		sendMessageAttached() {
			this.addFileAttach({
				event_book: this.selectedEvent.id,
				file: this.fileToSend,
			});

			this.clearMessage();
		},

		clearMessage() {
			this.message = null;
		},

		showContentClick() {
			this.showContent = !this.showContent;
		},
	},
};
</script>

<style lang="scss" scoped>
.event-chat {
	#message-list {
		overflow: auto;
	}

	form.send-message {
		width: 100%;
	}

	.content-event {

		.content-info {
			display: flex;
			justify-content: space-between;
		}

		.content-image {
			flex-basis: auto;
		}

		.content-description {
			flex-basis: 80%;
			padding: 16px;
			border-radius: 5px;
		}
	}
}
</style>
