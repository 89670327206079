<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<!-- Activar Formulario -->
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" dark v-bind="attrs" v-on="on">
				Config. Asuntos
			</v-btn>
		</template>

		<v-card>
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title
					>Asuntos que se muestran al crear un evento</v-toolbar-title
				>
			</v-toolbar>
			<v-card-text>
				<v-row>
					<v-col cols="4">
						<form>
							<v-text-field
								v-model="info.subject"
								label="Asunto"
								type="text"
								required
							></v-text-field>
							<v-select
								:items="associationAreas"
								v-model="info.association_area"
								item-text="name"
								item-value="id"
								label="Area"
								required
							></v-select>
							<v-select
								:items="eventTypes"
								v-model="info.type"
								item-text="name"
								item-value="type"
								label="Tipo de evento"
								required
							></v-select>
							<v-btn @click="saveSubjectForm" color="primary"
								>Agregar</v-btn
							>
						</form>
					</v-col>

					<v-col cols="4">
						<v-expansion-panels accordion class="mt-3">
							<v-expansion-panel
								v-for="area in areas"
								:key="area.id"
							>
								<v-expansion-panel-header>
									{{ area.name }}
								</v-expansion-panel-header>

								<v-expansion-panel-content>
									<v-expansion-panels>
										<v-expansion-panel
											v-for="type in area.types"
											:key="type.code"
										>
											<v-expansion-panel-header>
												{{ type.name }}
											</v-expansion-panel-header>

											<v-expansion-panel-content>
												<v-container>
													<v-row
														v-for="(
															subject, index
														) in type.subjects"
														:key="index"
													>
														<v-col>{{
															subject.subject
														}}</v-col>
														<v-col cols="1">
															<v-btn
																icon
																@click="
																	deleteSubject(
																		subject.id
																	)
																"
															>
																<v-icon
																	>mdi-delete-outline</v-icon
																>
															</v-btn>
														</v-col>
													</v-row>
												</v-container>
											</v-expansion-panel-content>
										</v-expansion-panel>
									</v-expansion-panels>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-col>

					<v-col cols="4">
						<p class="pt-3">
							La imagen muestra un ejemplo del lugar donde se
							visualiza el contenido de los asuntos creados. En
							este caso en la imagen, el regante desea generar una
							"Solicitud de Atención" al área de "Finanzas",
							entonces en el campo desplegable que dice
							"Selecciona un asunto" se mostraría las opciones
							creadas por los usuarios administradores de ACM.
						</p>
						<v-img
							max-width="373px"
							max-height="812px"
							src="@/assets/app-regante.png"
						></v-img>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'event-manage-subjects',

		data() {
			return {
				dialog: false,

				info: {
					subject: null,
					association_area: null,
					type: null,
				},

				areas: [],
			};
		},

		watch: {
			dialog: {
				handler() {
					this.info = {
						subject: null,
						association_area: null,
						type: null,
					};
				},
			},

			areasList: {
				handler(value) {
					this.areas = value;
				},
			},
		},

		computed: {
			...mapState('userControl', ['associationAreas']),
			...mapState('eventBookControl', ['eventTypes']),
			...mapState('eventBookSubjectsControl', ['subjects']),

			areasList() {
				const areas = this.$utils.deepCopy(this.associationAreas),
					types = this.$utils.deepCopy(this.eventTypes),
					subjects = this.$utils.deepCopy(this.subjects),
					list = [];
				let active = true;

				areas.forEach(item => {
					const area = {
						id: item.id,
						name: item.name,
						code: item.code,
						active,
					};

					const subjectList = subjects.filter(
						s => s.association_area.id == area.id
					);

					if (subjectList.length > 0) {
						area.types = [];

						types.forEach(itemType => {
							const type = {
								code: itemType.type,
								name: itemType.name,
							};

							const typeList = subjectList.filter(
								t => t.type == type.code
							);

							if (typeList.length > 0) {
								type.subjects = typeList.map(tl => ({
									id: tl.id,
									subject: tl.subject,
								}));

								area.types.push(type);
							}
						});

						list.push(area);
					}

					active = false;
				});

				return list;
			},
		},

		mounted() {
			this.loadSubjects();
		},

		methods: {
			...mapActions('eventBookSubjectsControl', [
				'loadSubjects',
				'addSubject',
				'deleteSubject',
			]),

			saveSubjectForm() {
				this.addSubject(this.info).then(() => {
					this.info = {
						subject: null,
						type: null,
						association_area: null,
					};
				});
			},

			close() {
				this.dialog = false;
			},
		},
	};
</script>
