<template>
	<v-row>
		<v-col v-if="showElement" cols="2"
			><v-select
				v-model="area"
				:items="areas"
				item-text="name"
				item-value="id"
				label="Area"
				dense
				outlined
			></v-select
		></v-col>
		<v-col v-if="watchmans.length" cols="2">
			<v-select
				v-model="watchman"
				:items="watchmanList"
				item-text="name"
				item-value="userid"
				label="Celador"
				dense
				outlined
			></v-select>
		</v-col>
		<v-col cols="2">
			<v-select
				v-model="type"
				:items="types"
				item-text="name"
				item-value="type"
				label="Tipo Evento"
				dense
				outlined
			></v-select>
		</v-col>
		<v-col cols="2"
			><v-select
				v-model="closed"
				:items="statusOptions"
				item-text="name"
				item-value="value"
				label="Estatus"
				dense
				outlined
			></v-select
		></v-col>
		<v-col cols="2">
			<v-menu
				ref="rangeDate"
				v-model="dateMenu"
				:close-on-content-click="false"
				:return-value.sync="dates"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="datesShow"
						label="Fecha"
						prepend-inner-icon="mdi-calendar"
						readonly
						v-bind="attrs"
						v-on="on"
						outlined
						dense
						@click:append="clearDate"
						:append-icon="datesShow.length ? 'mdi-close' : ''"
						appe
					></v-text-field>
				</template>
				<v-date-picker
					v-model="dates"
					range
					no-title
					scrollable
					@change="changeDate"
				>
				</v-date-picker>
			</v-menu>
		</v-col>
		<v-col v-if="showElement" cols="2">
			<v-btn color="primary" @click="download()">Descargar</v-btn>
		</v-col>
	</v-row>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: 'event-list-filter',

		data() {
			return {
				type: '',

				watchman: '',

				dateMenu: false,
				datesShow: '',
				dates: ['', ''],

				closed: '',
				area: '',

				statusOptions: [
					{ name: 'Todos', value: '' },
					{ name: 'Abierto', value: false },
					{ name: 'Cerrado', value: true },
				],
			};
		},

		watch: {
			watchman(watchman) {
				/* obtengo los otros filtros */
				const last = this.eventsLastVisible;
				last.next = 0;
				last.count = 0;
				delete last.watchman;

				let options = last;

				if (watchman != '') options = { ...last, ...{ watchman } };

				this.resetEvents();
				this.loadEvents(options);
			},

			type(type) {
				/* obtengo los otros filtros */
				const last = this.eventsLastVisible;
				last.next = 0;
				last.count = 0;
				delete last.type;

				let options = last;

				if (type != '') options = { ...last, ...{ type } };

				this.resetEvents();
				this.loadEvents(options);
			},

			closed(closed) {
				/* obtengo los otros filtros */
				const last = this.eventsLastVisible;
				last.next = 0;
				last.count = 0;
				delete last.closed;

				let options = last;

				if (closed !== '') options = { ...last, ...{ closed } };

				this.resetEvents();
				this.loadEvents(options);
			},

			area(areaID) {
				/* obtengo los otros filtros */
				const last = this.eventsLastVisible;
				last.next = 0;
				last.count = 0;
				delete last.areaID;

				let options = last;

				if (areaID != '') options = { ...last, ...{ areaID } };

				this.resetEvents();
				this.loadEvents(options);
			},

			dates(dates) {
				if (
					dates.length == 2 &&
					dates[0].length == 10 &&
					dates[1].length == 10
				) {
					/* obtengo los otros filtros */
					const last = this.eventsLastVisible;
					last.next = 0;
					last.count = 0;
					delete last.dateStart;
					delete last.dateEnd;

					let options = last;

					let dateOrdered = this.$utils.deepCopy(dates).sort();

					let dateStart = this.$utils
							.moment(
								dateOrdered[0] + ' 00:00',
								'YYYY-MM-DD HH:mm'
							)
							.utc()
							.format('YYYY-MM-DD HH:mm'),
						dateEnd = this.$utils
							.moment(
								dateOrdered[1] + ' 23:59',
								'YYYY-MM-DD HH:mm'
							)
							.utc()
							.format('YYYY-MM-DD HH:mm');
					options = { ...last, ...{ dateStart, dateEnd } };

					this.resetEvents();
					this.loadEvents(options);
					this.$refs.rangeDate.save(dates);
				}
			},
		},

		computed: {
			...mapState('userControl', [
				'associationAreas',
				'watchmans',
				'user',
			]),
			...mapState('eventBookControl', [
				'eventsLastVisible',
				'eventTypes',
			]),

			showElement() {
				return (
					this.user &&
					this.user.association_area &&
					!['watchman', 'sectionm'].includes(
						this.user.association_area.code
					)
				);
			},

			options() {
				const type = this.type;
				const options = {
					...this.$utils.deepCopy(this.eventsLastVisible),
					...{ type },
				};

				return options;
			},

			watchmanList() {
				const options = [{ name: 'Todos', userid: '' }];

				return options.concat(
					this.watchmans.map(item => ({
						name: item.name,
						userid: item.user ? item.user.id : '',
					}))
				);
			},

			types() {
				const options = [{ name: 'Todas', type: '' }];

				return options.concat(this.eventTypes);
			},

			areas() {
				const options = [{ name: 'Todas', id: '' }];

				return options.concat(this.associationAreas);
			},
		},

		mounted() {
			this.loadWatchmanList();
		},

		methods: {
			...mapActions('eventBookControl', [
				'loadEvents',
				'resetEvents',
				'downloadEventsReport',
			]),
			...mapActions('userControl', ['loadWatchmanList']),

			clearDate() {
				this.datesShow = '';
				this.dates[0] = '';
				this.dates[1] = '';

				const last = this.eventsLastVisible;
				last.next = 0;
				last.count = 0;
				delete last.dateStart;
				delete last.dateEnd;

				let options = last;
				this.resetEvents();
				this.loadEvents(options);
			},

			changeDate() {
				if (this.dates[0] == '') {
					this.datesShow = '';
				} else {
					let dateOrdered = this.$utils.deepCopy(this.dates).sort();

					if (this.dates[0] == this.dates[1]) {
						this.datesShow =
							'Del ' +
							this.$utils
								.moment(dateOrdered[0], 'YYYY-MM-DD')
								.format('DD/MM/YYYY');
					} else {
						this.datesShow =
							'Del ' +
							this.$utils
								.moment(dateOrdered[0], 'YYYY-MM-DD')
								.format('DD/MM/YYYY');
						this.datesShow +=
							' al ' +
							this.$utils
								.moment(dateOrdered[1], 'YYYY-MM-DD')
								.format('DD/MM/YYYY');
					}
				}
			},

			download() {
				this.downloadEventsReport(this.eventsLastVisible);
			},
		},
	};
</script>
