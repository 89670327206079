<template>
	<v-simple-table fixed-header :height="heightList">
		<template v-slot:default>
			<thead>
				<tr>
					<th v-for="(item, index) in headers" :key="`head-${index}`">
						{{ item.text }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(row, indexRow) in eventList"
					:key="`event-${indexRow}`"
					:class="
						selectedEvent.id == row.id ? 'yellow lighten-4' : ''
					"
				>
					<td
						v-for="(col, indexCol) in headers"
						:key="`${indexRow}-col-${indexCol}`"
					>
						<template v-if="col.value == 'type'">
							<v-chip
								:class="row.class"
								@click="selectEvent(row.fulldata)"
							>
								{{ row[col.value] }}
							</v-chip>
						</template>

						<template v-else-if="col.value == 'status'">
							<v-btn
								fab
								dark
								x-small
								color="green"
								v-if="row.closed"
								depressed
								@click="selectEvent(row.fulldata)"
							>
								<v-icon dark>mdi-lock-outline</v-icon>
							</v-btn>

							<v-btn
								v-else
								fab
								x-small
								depressed
								@click="selectEvent(row.fulldata)"
							>
								<v-icon>mdi-lock-open-variant-outline</v-icon>
							</v-btn>
						</template>

						<template v-else-if="col.value == 'subject'">
							<span v-html="`${row[col.value]}<br />`" />
							<span
								class="text-caption"
								v-if="row['fulldata'].visitreport_data"
								v-html="
									`Fecha de visita: ${$utils
										.moment(
											row['fulldata'].visitreport_data
												.date
										)
										.format('DD/MM/YYYY HH:mm')}<br />`
								"
							/>
							<span
								class="text-caption"
								v-if="row['irrigator']"
								v-html="`${row['irrigator']}<br />`"
							/>
							<span
								class="text-caption"
								v-if="row['channel']"
								v-html="`${row['channel']}<br />`"
							/>
							<span
								class="text-caption"
								v-if="row['fulldata'].association_user"
								v-html="
									`Por: ${row['fulldata'].association_user.name}`
								"
							/>
						</template>

						<template v-else>
							{{ row[col.value] }}
						</template>
					</td>
				</tr>
			</tbody>
		</template>
	</v-simple-table>
</template>

<script>
	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'event-list',

		props: {
			heightList: {
				type: String,
				default: null,
			},
		},

		data() {
			return {
				headers: [
					{ text: '#', value: 'item_number' },
					{ text: 'Tipo', value: 'type' },
					{ text: 'Area', value: 'area' },
					{ text: 'Asunto', value: 'subject' },
					{ text: 'Creado', value: 'created' },
					{ text: 'Status', value: 'status' },
				],
				loading: false,
			};
		},

		computed: {
			...mapState({
				events: state => state.eventBookControl.events,
				eventTypes: state => state.eventBookControl.eventTypes,
				selectedEvent: state => state.eventBookControl.selectedEvent,
			}),

			eventList() {
				return this.events.map((item, index) => {
					return {
						item_number: index + 1,
						type: this.eventTypes.reduce(
							(acc, curr) =>
								curr.type == item.type ? curr.name : acc,
							''
						),
						area: item.association_area
							? item.association_area.name
							: '',
						subject: item.subject,
						class: this.eventTypes.reduce(
							(acc, curr) =>
								curr.type == item.type ? curr.class : acc,
							''
						),
						created: this.$utils
							.moment(item.createdAt)
							.format('DD/MM/YYYY HH:mm'),
						closed: item.closed,

						status: '',

						id: item.id,

						fulldata: item,

						irrigator: item.irrigator
							? `Regante: ${item.irrigator.name} (${item.irrigator.code})`
							: null,

						channel: item.channel
							? `${item.channel.name} (${item.channel.code})`
							: null,
					};
				});
			},
		},

		methods: {
			...mapActions('eventBookControl', ['selectEvent']),
		},
	};
</script>
