<template>
	<div
		class="open-map"
		ref="map-root"
		style="width: 100%; height: 150px"
	></div>
</template>

<script>
	import View from 'ol/View';
	import Map from 'ol/Map';
	import TileLayer from 'ol/layer/Tile';
	import VectorLayer from 'ol/layer/Vector';

	import OSM from 'ol/source/OSM';
	import VectorSource from 'ol/source/Vector';

	import { fromLonLat } from 'ol/proj';
	import { Point } from 'ol/geom';
	import Feature from 'ol/Feature';
	import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
	// Ejemplo https://codesandbox.io/s/openlayers-circle-at-current-location-forked-3vnnf?file=/src/components/MapContainer.vue:2270-3672
	// importing the OpenLayers stylesheet is required for having
	// good looking buttons!
	import 'ol/ol.css';

	export default {
		name: 'MapContainer',
		components: {},
		props: {
			coord: {
				type: Object,
			},
		},

		data() {
			return {
				vectorLayer: null,
				center: fromLonLat([this.coord.lng, this.coord.lat]),
				positionFeature: new Feature(),
				circle: new Style({
					image: new CircleStyle({
						radius: 10,
						fill: new Fill({
							color: '#1976d2',
						}),
						stroke: new Stroke({
							color: '#ffff00',
							width: 8,
						}),
					}),
				}),
			};
		},
		mounted() {
			// A view obj
			this.view = new View({
				zoom: 16,
				center: this.center,
				constrainResolution: true,
			});

			// Assign the styles to the positionFeature
			this.positionFeature.setStyle(this.circle);

			// a new vector layer is created with the feature
			this.vectorLayer = new VectorLayer({
				source: new VectorSource({
					// features: [this.positionFeature] // <== doe not work
					features: [],
				}),
			});

			// The tile layer (the map)
			this.tileLayer = new TileLayer({
				source: new OSM(), // tiles are served by OpenStreetMap
			});

			// this is where we create the OpenLayers map
			this.olMap = new Map({
				// the map will be created using the 'map-root' ref
				target: this.$refs['map-root'],
				layers: [
					// adding a background tiled layer
					this.tileLayer,
					// the vector layer is added above the tiled OSM layer
					this.vectorLayer,
				],

				// the map view will initially show the whole world
				view: this.view,
			});

			const coordinates = this.center;

			const point = new Point(coordinates);

			this.positionFeature.setGeometry(coordinates ? point : null);

			const source = this.vectorLayer.getSource();
			source.addFeature(this.positionFeature);
		},
	};
</script>
