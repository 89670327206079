var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px","hide-overlay":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","block":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Agregar evento ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Registrar evento")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"form-add-event",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.associationAreas,"item-text":"name","item-value":"id","label":"Asignar al área","solo":""},model:{value:(_vm.association_area),callback:function ($$v) {_vm.association_area=$$v},expression:"association_area"}}),_c('v-select',{attrs:{"items":_vm.eventTypesForArea,"item-text":"name","item-value":"type","label":"Tipo de evento a registra","solo":""},on:{"change":function($event){_vm.eventData.channel_code = '';
								_vm.eventData.irrigator_code = '';}},model:{value:(_vm.eventData.type),callback:function ($$v) {_vm.$set(_vm.eventData, "type", $$v)},expression:"eventData.type"}}),(_vm.eventData.type)?[(_vm.eventData.type == 'channelreport')?_c('v-autocomplete',{attrs:{"required":"","items":_vm.channels,"loading":_vm.isLoading,"search-input":_vm.search,"label":"Código de canal","color":"white","hide-no-data":"","item-text":"name","item-value":"code","placeholder":"Código de canal","solo":"","id":"channel-search"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.eventData.channel_code),callback:function ($$v) {_vm.$set(_vm.eventData, "channel_code", $$v)},expression:"eventData.channel_code"}}):_c('v-autocomplete',{attrs:{"required":"","items":_vm.irrigatorsList,"loading":_vm.isLoading,"search-input":_vm.search,"color":"white","hide-no-data":"","item-text":"irrigatorName","item-value":"irrigatorCode","label":"Regantes","placeholder":"Escriba el codigo o nombre","solo":"","id":"irrigator-search"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.eventData.irrigator_code),callback:function ($$v) {_vm.$set(_vm.eventData, "irrigator_code", $$v)},expression:"eventData.irrigator_code"}}),(_vm.eventData.type == 'visitreport')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de activación","persistent-hint":"","prepend-inner-icon":"mdi-calendar","required":"","readonly":"","autocomplete":"off","solo":""},model:{value:(_vm.visitReportDataDate),callback:function ($$v) {_vm.visitReportDataDate=$$v},expression:"visitReportDataDate"}},'v-text-field',attrs,false),on))]}}],null,false,591338245),model:{value:(_vm.dialogVisitReportDataDate),callback:function ($$v) {_vm.dialogVisitReportDataDate=$$v},expression:"dialogVisitReportDataDate"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dialogVisitReportDataDate = false}},model:{value:(_vm.visitReportDataDate),callback:function ($$v) {_vm.visitReportDataDate=$$v},expression:"visitReportDataDate"}})],1):_vm._e(),(_vm.eventData.type == 'visitreport')?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hora","prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","required":"","solo":""},model:{value:(_vm.visitReportDataTime),callback:function ($$v) {_vm.visitReportDataTime=$$v},expression:"visitReportDataTime"}},'v-text-field',attrs,false),on))]}}],null,false,4036093393),model:{value:(_vm.dialogVisitReportDataTime),callback:function ($$v) {_vm.dialogVisitReportDataTime=$$v},expression:"dialogVisitReportDataTime"}},[(_vm.dialogVisitReportDataTime)?_c('v-time-picker',{attrs:{"full-width":"","ampm-in-title":""},on:{"click:minute":function($event){_vm.dialogVisitReportDataTime = false}},model:{value:(_vm.visitReportDataTime),callback:function ($$v) {_vm.visitReportDataTime=$$v},expression:"visitReportDataTime"}}):_vm._e()],1):_vm._e(),(
									!_vm.possibleSubjects.length ||
									_vm.activateFieldOther
								)?_c('v-text-field',{attrs:{"append-icon":"mdi-format-list-bulleted","maxlength":"60","solo":"","required":"","placeholder":"Escribe aquí tu asunto","label":"Asunto del evento"},on:{"click:append":_vm.chageToSelectSubject},model:{value:(_vm.eventData.subject),callback:function ($$v) {_vm.$set(_vm.eventData, "subject", $$v)},expression:"eventData.subject"}}):_c('v-select',{attrs:{"items":_vm.possibleSubjects,"label":"Elige un asunto","required":"","solo":""},on:{"change":_vm.changeToOtherSubject},model:{value:(_vm.eventData.subject),callback:function ($$v) {_vm.$set(_vm.eventData, "subject", $$v)},expression:"eventData.subject"}}),_c('v-textarea',{attrs:{"name":"input-7-1","label":"Descripción","required":"","solo":""},model:{value:(_vm.eventData.content),callback:function ($$v) {_vm.$set(_vm.eventData, "content", $$v)},expression:"eventData.content"}})]:_vm._e(),(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}})],1):_vm._e()],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.desactiveSend},on:{"click":_vm.sendFormToAddEvent}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }