<template>
	<v-col cols="6" :offset="byUser.offset">
		<v-card :color="byUser.color">
			<v-card-text>
				<div class="user-data" v-if="message.user">
					{{ message.user.name }}<br />
					<span v-if="message.user.association_area">
						{{ message.user.association_area.name }}
					</span>
					<span v-else> Regante </span>
				</div>

				<template v-if="showImage && metadata.size">
					<!-- Image -->
					<div>
						<img
							:src="imgUrl"
							width="100%"
							@click="download(metadata.url)"
						/>
						<br />
						<v-btn
							depressed
							fab
							x-small
							color="primary"
							@click="download(metadata.url)"
						>
							<v-icon>mdi-download-outline</v-icon>
						</v-btn>
						{{ metadata.size }}
					</div>
				</template>

				<template v-else-if="metadata.size">
					<!-- File -->
					<div>
						<div class="mb-2">
							<audio
								v-if="metadata.ext === '.ogg'"
								:src="metadata.url"
								controls
							></audio>
							<template v-else>
								Haga clic en el icono de descarga, para obtener
								el archivo
								<v-icon>{{ showIcon.icon }}</v-icon>
								{{ showIcon.name }}
							</template>
						</div>
						<v-btn
							depressed
							fab
							x-small
							color="primary"
							@click="download(metadata.url)"
						>
							<v-icon>mdi-download-outline</v-icon>
						</v-btn>
						{{ metadata.size }}
					</div>
				</template>

				<OpenMap v-if="message.coordinates" :coord="center" />
				<a
					v-if="message.coordinates"
					target="_blank"
					:href="`https://maps.google.com/maps?q=${message.coordinates.latitude}%2C${message.coordinates.longitude}&z=17&hl=es`"
					>Ver mapa en Google</a
				>

				<template>
					{{ message.message }}
				</template>

				<div class="text-right">
					<v-chip x-small>
						{{ dateMessage }}
					</v-chip>
				</div>
			</v-card-text>
		</v-card>
	</v-col>
</template>

<script>
	/* Componente que solo recibe la informacion del mensaje y le da formato */
	import { mapState } from 'vuex';
	import OpenMap from '@/components/OpenMap';

	export default {
		name: 'even-list-chat-message',

		data() {
			return {
				mimeTypesShow: ['image/jpeg', 'image/png'],
				mimeTypes: [
					{
						name: 'Adjunto',
						type: ['application/octet-stream'],
						icon: 'mdi-file-image-outline',
					},
					{
						name: 'Documento Excel',
						type: [
							'application/wps-office.xlsx',
							'application/wps-office.xls',
						],

						icon: 'mdi-file-excel-outline',
					},
					{
						name: 'Documento PDF',
						type: ['application/pdf'],
						icon: 'mdi-file-pdf-outline',
					},
					{
						name: 'Documento PowerPoint',
						type: [
							'application/wps-office.pptx',
							'application/wps-office.ppt',
						],
						icon: 'mdi-file-powerpoint-outline',
					},
					{
						name: 'Documento Word',
						type: [
							'application/wps-office.docx',
							'application/wps-office.doc',
						],
						icon: 'mdi-file-word-outline',
					},
				],
			};
		},

		props: {
			message: {
				type: Object,
				requered: true,
			},
		},

		components: {
			OpenMap,
		},

		computed: {
			...mapState({
				associationUser: state => state.userControl.user,
				areas: state => state.userControl.associationAreas,
			}),

			byUser() {
				return this.message.user.id == this.associationUser.user.id
					? { color: '#ffffd1', offset: 6 }
					: { color: 'white', offset: 0 };
			},

			dateMessage() {
				return this.$utils
					.moment(this.message.createdAt)
					.format('DD-MM-YYYY hh:mm a');
			},

			metadata() {
				let data = {};
				if (this.message.attached) {
					data = { ...this.message.attached };
					data.size = this.$utils.formatBytes(data.size * 1000);
					data.url = process.env.VUE_APP_API + data.url;
				}

				return data;
			},

			showImage() {
				return this.mimeTypesShow.includes(this.metadata.mime);
			},

			showIcon() {
				const icon = this.mimeTypes.filter(item =>
					item.type.includes(this.metadata.mime)
				);
				return icon.length > 0 ? icon[0] : this.mimeTypes[0];
			},

			imgUrl() {
				return this.metadata
					? process.env.VUE_APP_API +
							this.metadata.formats.thumbnail.url
					: '';
			},

			center() {
				return {
					lat: this.message.coordinates
						? this.message.coordinates.latitude
						: 0,
					lng: this.message.coordinates
						? this.message.coordinates.longitude
						: 0,
				};
			},
		},

		methods: {
			download(url) {
				window.open(url, '_blank');
			},
		},
	};
</script>

<style lang="scss" scoped>
	audio {
		width: 100%;
	}

	.user-data {
		font-size: 12px;
		line-height: 11px;
		margin-bottom: 5px;

		span {
			font-size: 10px;
		}
	}
</style>
