<template>
	<v-dialog v-model="dialog" persistent max-width="600px" hide-overlay>
		<template v-slot:activator="{ on, attrs }">
			<v-btn color="primary" block v-bind="attrs" v-on="on">
				<v-icon>mdi-plus</v-icon> Agregar evento
			</v-btn>
		</template>

		<v-card>
			<v-card-title>
				<span class="headline">Registrar evento</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12" class="form-add-event">
							<v-select
								v-model="association_area"
								:items="associationAreas"
								item-text="name"
								item-value="id"
								label="Asignar al área"
								solo
							></v-select>

							<v-select
								v-model="eventData.type"
								:items="eventTypesForArea"
								item-text="name"
								item-value="type"
								label="Tipo de evento a registra"
								solo
								@change="
									eventData.channel_code = '';
									eventData.irrigator_code = '';
								"
							></v-select>

							<template v-if="eventData.type">
								<v-autocomplete
									v-if="eventData.type == 'channelreport'"
									required
									v-model="eventData.channel_code"
									:items="channels"
									:loading="isLoading"
									:search-input.sync="search"
									label="Código de canal"
									color="white"
									hide-no-data
									item-text="name"
									item-value="code"
									placeholder="Código de canal"
									solo
									id="channel-search"
								></v-autocomplete>

								<v-autocomplete
									v-else
									required
									v-model="eventData.irrigator_code"
									:items="irrigatorsList"
									:loading="isLoading"
									:search-input.sync="search"
									color="white"
									hide-no-data
									item-text="irrigatorName"
									item-value="irrigatorCode"
									label="Regantes"
									placeholder="Escriba el codigo o nombre"
									solo
									id="irrigator-search"
								></v-autocomplete>

								<v-menu
									v-if="eventData.type == 'visitreport'"
									v-model="dialogVisitReportDataDate"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="visitReportDataDate"
											label="Fecha de activación"
											persistent-hint
											prepend-inner-icon="mdi-calendar"
											v-bind="attrs"
											v-on="on"
											required
											readonly
											autocomplete="off"
											solo
										></v-text-field>
									</template>
									<v-date-picker
										v-model="visitReportDataDate"
										no-title
										@input="
											dialogVisitReportDataDate = false
										"
									></v-date-picker>
								</v-menu>

								<v-menu
									v-if="eventData.type == 'visitreport'"
									v-model="dialogVisitReportDataTime"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="visitReportDataTime"
											label="Hora"
											prepend-inner-icon="mdi-clock-time-four-outline"
											readonly
											required
											v-bind="attrs"
											v-on="on"
											solo
										></v-text-field>
									</template>
									<v-time-picker
										v-if="dialogVisitReportDataTime"
										v-model="visitReportDataTime"
										full-width
										ampm-in-title
										@click:minute="
											dialogVisitReportDataTime = false
										"
									></v-time-picker>
								</v-menu>

								<v-text-field
									v-if="
										!possibleSubjects.length ||
										activateFieldOther
									"
									v-model="eventData.subject"
									append-icon="mdi-format-list-bulleted"
									@click:append="chageToSelectSubject"
									maxlength="60"
									solo
									required
									placeholder="Escribe aquí tu asunto"
									label="Asunto del evento"
								/>

								<v-select
									v-else
									:items="possibleSubjects"
									label="Elige un asunto"
									v-model="eventData.subject"
									@change="changeToOtherSubject"
									required
									solo
								/>

								<v-textarea
									name="input-7-1"
									label="Descripción"
									required
									solo
									v-model="eventData.content"
								></v-textarea>
							</template>

							<div v-if="loading" class="loading">
								<v-progress-linear
									color="deep-purple accent-4"
									indeterminate
									rounded
									height="6"
								></v-progress-linear>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="blue darken-1" text @click="dialog = false">
					Cancelar
				</v-btn>

				<v-btn
					color="primary"
					@click="sendFormToAddEvent"
					:disabled="desactiveSend"
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	/* Formulario para agrega eventos */
	import { mapState, mapActions } from 'vuex';

	export default {
		name: 'event-form-add',

		data() {
			return {
				dialog: false,
				loading: false,

				activateFieldOther: false,

				eventData: {
					subject: '',
					content: '',
					type: '',
					association_user: '',
					irrigator_code: '',
					channel_code: '',
				},

				dialogVisitReportDataDate: false,
				visitReportDataDate: '',

				dialogVisitReportDataTime: false,
				visitReportDataTime: '',

				association_area: '',

				/* Autocomplet */
				isLoading: false,
				search: null,
			};
		},

		watch: {
			dialog() {
				// Se limpia los datos y se actualiza la fecha para el formulario
				this.eventData = {
					subject: '',
					content: '',
					type: '',
					association_user: this.user.id,
					irrigator_code: '',
					channel_code: '',
				};

				this.visitReportDataDate = '';

				/* Reseteo las variables del filtro para obtener los regantes */
				this.clearIrrigators();
			},

			association_area: {
				handler() {
					this.visitReportDataDate = '';
					this.visitReportDataTime = '';

					this.eventData.irrigator_code = '';
					this.eventData.channel_code = '';
				},
				deep: true,
			},

			eventData: {
				handler(newType, oldType) {
					if (newType.type !== oldType.type) this.clearIrrigators();
				},
				deep: true,
			},

			search(val) {
				this.searchIrrigator(val);
			},
		},

		computed: {
			...mapState({
				user: state => state.userControl.user,
				userType: state => state.userControl.type,
				selectedEventType: state =>
					state.eventBookControl.selectedEventType,
				eventTypes: state => state.eventBookControl.eventTypes,
				optionsEventBook: state =>
					state.eventBookControl.optionsEventBook,
				associationAreas: state => state.userControl.associationAreas,

				/* Regantes del autocompledato */
				irrigators: state => state.irrigatorControl.irrigators,
				channels: state => state.irrigatorControl.channels,
			}),

			irrigatorsList() {
				return this.irrigators.map(item => {
					return {
						irrigatorCode: item.code,
						irrigatorName: `${item.code} ${item.name}`,
					};
				});
			},

			possibleSubjects() {
				// Se escogen los asuntos predefinidos en el sistema
				let options = this.eventTypes.reduce(
					(acc, curr) =>
						curr.type == this.eventData.type ? curr : acc,
					{}
				);

				/* Debe venir solo un elemento en el array si se encontró el tipo de evento, por eso se usa el options[0] */
				options = options.possibleSubjects
					? options.possibleSubjects
							.filter(
								x => x.association_area == this.association_area
							)
							.map(x => x.subject)
					: [];

				if (options.length) options.push('Otro');

				return options;
			},

			eventDataToSend() {
				let data = this.$utils.deepCopy(this.eventData);
				data.association_area = this.association_area;

				if (this.visitReportDataDate)
					data.visitreport_data = {
						date: `${this.visitReportDataDate} ${this.visitReportDataTime}`,
					};

				return data;
			},

			eventTypesForArea() {
				const areaCode = this.associationAreas.reduce(
					(acc, curr) =>
						curr.id == this.association_area ? curr.code : acc,
					''
				);
				return areaCode != 'watchman' || this.userType != 'watchman'
					? this.eventTypes.filter(
							item =>
								!['channelreport', 'visitreport'].includes(
									item.type
								)
					  )
					: this.eventTypes;
			},

			desactiveSend() {
				if (
					this.association_area &&
					this.eventData.type &&
					this.eventData.irrigator_code &&
					this.eventData.subject.length &&
					this.eventData.content.length
				)
					return false;
				return true;
			},
		},

		destroyed() {
			/* Reseteo las variables del filtro para obtener los regantes */
			this.clearIrrigators();
		},

		mounted() {
			this.loadAssociationAreas();
		},

		methods: {
			...mapActions('userControl', ['loadAssociationAreas']),
			...mapActions('eventBookControl', ['addEvent']),
			...mapActions('irrigatorControl', [
				'updateFilterOptions',
				'clearIrrigators',
			]),

			sendFormToAddEvent() {
				this.loading = true;

				this.addEvent(this.eventDataToSend).finally(() => {
					this.loading = false;
					this.dialog = false;
				});
			},

			changeToOtherSubject() {
				if (this.eventData.subject.toLowerCase() == 'otro') {
					this.activateFieldOther = true;
					this.eventData.subject = '';
				}
			},

			chageToSelectSubject() {
				this.eventData.subject = '';
				this.activateFieldOther = false;
			},

			searchIrrigator(val) {
				/* Seteo el filtro para buscar las opciones de regantes */
				if (
					val &&
					val.length > 2 &&
					(this.eventData.irrigator_code == '' ||
						!val.includes(this.eventData.irrigator_code))
				) {
					this.isLoading = true;
					this.updateFilterOptions({
						code: val,
						perPage: 200,
					}).finally(() => (this.isLoading = false));
				}
			},
		},
	};
</script>
