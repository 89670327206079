var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{attrs:{"fixed-header":"","height":_vm.heightList},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(item,index){return _c('th',{key:("head-" + index)},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.eventList),function(row,indexRow){return _c('tr',{key:("event-" + indexRow),class:_vm.selectedEvent.id == row.id ? 'yellow lighten-4' : ''},_vm._l((_vm.headers),function(col,indexCol){return _c('td',{key:(indexRow + "-col-" + indexCol)},[(col.value == 'type')?[_c('v-chip',{class:row.class,on:{"click":function($event){return _vm.selectEvent(row.fulldata)}}},[_vm._v(" "+_vm._s(row[col.value])+" ")])]:(col.value == 'status')?[(row.closed)?_c('v-btn',{attrs:{"fab":"","dark":"","x-small":"","color":"green","depressed":""},on:{"click":function($event){return _vm.selectEvent(row.fulldata)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-lock-outline")])],1):_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":""},on:{"click":function($event){return _vm.selectEvent(row.fulldata)}}},[_c('v-icon',[_vm._v("mdi-lock-open-variant-outline")])],1)]:(col.value == 'subject')?[_c('span',{domProps:{"innerHTML":_vm._s(((row[col.value]) + "<br />"))}}),(row['fulldata'].visitreport_data)?_c('span',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(
								("Fecha de visita: " + (_vm.$utils
									.moment(
										row['fulldata'].visitreport_data
											.date
									)
									.format('DD/MM/YYYY HH:mm')) + "<br />")
							)}}):_vm._e(),(row['irrigator'])?_c('span',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(((row['irrigator']) + "<br />"))}}):_vm._e(),(row['channel'])?_c('span',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(((row['channel']) + "<br />"))}}):_vm._e(),(row['fulldata'].association_user)?_c('span',{staticClass:"text-caption",domProps:{"innerHTML":_vm._s(
								("Por: " + (row['fulldata'].association_user.name))
							)}}):_vm._e()]:[_vm._v(" "+_vm._s(row[col.value])+" ")]],2)}),0)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }